<template>
  <div />
</template>
<script lang="js">
import api from '../api'

export default {
  created () {
    api.request('core', 'get', `short-link/${this.$route.params.token}`)
      .then((response) => {
        window.location.replace(response.data)
      })
      .catch(() => {
        this.$router.push({ path: '/' })
      })
  }
}
</script>
